import cn from 'classnames';
import { BaseProps } from 'types';

export interface NewsCardFooterProps extends BaseProps {}

const NewsCardFooter: React.FC<NewsCardFooterProps> = ({ className, children }) => (
  <div className={cn('news-section__footer', className)}>{children}</div>
);

export default NewsCardFooter;
